/* BASIC COLORS */
$backLight: #1c706e;
$backSemiDark: #0f4646;
$backAlmostDark: #112a2c;
$backDark: #061717;

$backLogo: #0c2524;
$backProgress: #4c3393;

$lightBlueTransparent: rgba(80, 227, 194, 0.2);
$lightBlueSemiTransparent: rgba(11, 255, 250, 0.5);
$lightBlue: #50e3c2;
$lightBlueActive: #0bfffa;
$lightGreen: #beff7b;
$grey: #c5c5c5;

$fontDark: #061717;
$fontGrey: #c5c5c5;
$fontBlue: #9e66f2;
$fontDarkBlue: #4c3393;
$fontHighlighted: #27bda0;

$borderFrame: #3bbdba;
$inputBack: #0b4343;

$searchResultBack: #0b4343;
$searchResultActive: #286161;

$alert: #a94442;

$error: #a94442;

$white: #fff;

$albumImgBorderColor: #27918f;


/* PREDEFINED WIDTH OF SITE */
$maxWidth: 1280px;
$intermediateWidth: 1152px;
$mainWidth: 1024px;
$widthWithOutBorders: $mainWidth - 15px;
$mainContentWidth: $mainWidth - 216px; // minus menu width
$mainContentMaxWidth: $maxWidth - 216px + 16px;
$contentHeaderWidth: $mainWidth - 216px;

/* PREDEFINED HEIGHT OF SITE */
$headerHeight: 54px;
$playerHeight: 118px;
$mainContainerHeight: calc(100vh - 196px);
$mainContainerWithTopBarHeight: calc(100vh - 196px - 47px);
$mainProgramsContainerWithTopBarHeight: calc(100vh - 196px - 49px - 45px);
$mainProgramsContainerWithBiggerTopBarHeight: calc(100vh - 196px - 49px - 55px);
$mainProgramsContainerWithTopBarAndSecondaryBarHeight: calc(100vh - 196px - 49px - 49px - 45px);
$mainProgramsContainerWithBiggerTopBarAndSecondaryBarHeight: calc(100vh - 196px - 49px - 55px - 45px);
$mainAlbumsContainerWithTopBarHeight: calc(100vh - 196px - 49px - 49px);
$mainAlbumsContainerWithTopBarAndSecondaryBarHeight: calc(100vh - 196px - 49px - 49px - 45px);
$dropDownPopupBodyHeight: calc(100vh - 196px - 49px - 49px - 45px);

/* ALBUMS TABLE COLUMNS */
$columnAlbumsImagePlayWidth: 150px;
$albumsImageWidth: 80px;
$columnAlbumsLabelDateWidth: 140px;
$columnAlbumsGenreWidth: 200px;
$columnAlbumsRatingWidth: 125px;
$columnAlbumsPopularityWidth: 100px;
$columnAlbumsArtistTitleWidth: calc(100% - #{$columnAlbumsImagePlayWidth} - #{$columnAlbumsLabelDateWidth} - #{$columnAlbumsGenreWidth} - #{$columnAlbumsRatingWidth} - #{$columnAlbumsPopularityWidth});

/* PROGRAMS TABLE COLUMNS */
$columnProgramsImageWidth: 190px;
$columnProgramsPlayWidth: 50px;
$columnProgramsDateWidth: 80px;
$columnProgramsGenreWidth: 170px;
$columnProgramsRatingWidth: 125px;
$columnProgramsPopularityWidth: 60px;
$columnProgramsMenuWidth: 30px;

