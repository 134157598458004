@font-face {
  font-family: "BankGothic-Regular";
  src: url("/assets/fonts/BankGothic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  src: url("/assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web ExtraLight";
  src: url("/assets/fonts/TitilliumWeb-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web Light";
  src: url("/assets/fonts/TitilliumWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web LightItalic";
  src: url("/assets/fonts/TitilliumWeb-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web Italic";
  src: url("/assets/fonts/TitilliumWeb-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web SemiBold";
  src: url("/assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web Bold";
  src: url("/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}
